import * as MXTS from "@maxxton/cms-mxts-api";
import * as React from "react";

import { ALL_AMENITIES_AGGREGATION, UNITS_WITH_PRICE_AGGREGATION, getAvailability } from "../../../utils/availability.util";
import { FormSpec, iconList, localized, multiSelectStylePicker } from "../../../form-specs";
import {
    I18nACCOMMODATIONKIND,
    I18nAMENITY,
    I18nREGION,
    I18nRESORT,
    ItemType,
    getAmenitiesAutocompleteList,
    getFreeSearchItems,
    lazyLoadRegionOptions,
    resortOptions,
} from "../../../components/utils";
import { PageWidgetSpec, Widget } from "../../";
import { accoKindOptions, getMxtsEnv } from "../../mxts";

import { AVAILABILITY_CONSTANTS } from "../../../utils/constants";
import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { ContentType } from "../../../components/components.enum";
import { DynamicFreeSearch } from "./DynamicFreeSearch";
import { FreeSearchApi } from "@maxxton/cms-api";
import { FreeSearchData } from "./dynamicFreeSearch.types";
import { NumberMultiSelectOption } from "../../mxts/selectOption.types";
import { WidgetGroup } from "../../widget.enum";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getI18nLocaleObject } from "../../../i18n";
import namespaceList from "../../../i18n/namespaceList";

const { APPLICATION } = AVAILABILITY_CONSTANTS;

interface LocaleContent {
    moreButtonText: string;
    lessButtonText: string;
    locale: string;
}

export interface WidgetOptions {
    styleIds: any[];
    placeholder: string;
    useCustomFreeSearch: boolean;
    freeSearchItems: NumberMultiSelectOption[];
    fetchCount: boolean;
    contentTypes: NumberMultiSelectOption[];
    resorts: NumberMultiSelectOption[];
    resortIcon: string;
    accoKinds: NumberMultiSelectOption[];
    accoKindIcon: string;
    regions: number[];
    regionIcon: string;
    amenities: NumberMultiSelectOption[];
    amenityIcon: string;
    highlightInput: boolean;
    highlightColor: string;
    isMultiSelect?: boolean;
    displayType?: string;
    nrOfSearchResultsByDefault?: number;
    useToggleFilter?: boolean;
    localizedContent?: LocaleContent[];
    showParentAndChildRegionsInDropdown?: boolean;
    showMultiLevelDropdown?: boolean;
    showUnavailableRegions?: boolean;
    showRegionsBasedOnAvailability?: boolean;
    isPriorityBasedRegionOrder?: boolean;
}

const TARGETS = ["free-search"];
const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "dynamic-free-search-widget-options",
    name: getI18nLocaleObject(namespaceList.admin, "dynamicFreeSearchWidgetOptions"),
    pluralName: getI18nLocaleObject(namespaceList.admin, "dynamicFreeSearchWidgetOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            {
                                variable: "placeholder",
                                label: getI18nLocaleObject(namespaceList.pluginForm, "placeholder"),
                                type: "text",
                            },
                            {
                                variable: "useCustomFreeSearch",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.admin, "useCustomFreeSearch"),
                            },
                            {
                                variable: "displayType",
                                label: getI18nLocaleObject(namespaceList.searchAmenities, "displayType"),
                                type: "select",
                                default: "displayAsDropdown",
                                optionList: [
                                    {
                                        value: "displayAsList",
                                        label: getI18nLocaleObject(namespaceList.admin, "displayAsList"),
                                    },
                                    {
                                        value: "displayAsDropdown",
                                        label: getI18nLocaleObject(namespaceList.admin, "displayAsDropdown"),
                                    },
                                ],
                            },
                            {
                                variable: "showParentAndChildRegionsInDropdown",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.searchAmenities, "showParentAndChildRegionsInDropdown"),
                                visible: (options: WidgetOptions) => options.displayType === "displayAsDropdown",
                            },
                            {
                                variable: "showMultiLevelDropdown",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.searchAmenities, "showMultiLevelDropdown"),
                                visible: (options: WidgetOptions) => !!options.showParentAndChildRegionsInDropdown,
                            },
                            {
                                variable: "nrOfSearchResultsByDefault",
                                type: "text",
                                label: getI18nLocaleObject(namespaceList.admin, "nrOfSearchResultsByDefault"),
                                visible: (options: WidgetOptions) => options.displayType === "displayAsList",
                            },
                            {
                                variable: "useToggleFilter",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.searchAmenities, "toggleAmenities"),
                                visible: (options: WidgetOptions) => !!options.nrOfSearchResultsByDefault && options.displayType === "displayAsList",
                            },
                            localized({
                                variable: "localizedContent",
                                tabContent: [
                                    {
                                        variable: "moreButtonText",
                                        label: getI18nLocaleObject(namespaceList.searchAmenities, "moreAmenitiesButtonText"),
                                        type: "text" as const,
                                        placeholder: getI18nLocaleObject(namespaceList.admin, "placeholderForEmptyText"),
                                    },
                                    {
                                        variable: "lessButtonText",
                                        label: getI18nLocaleObject(namespaceList.searchAmenities, "lessAmenitiesButtonText"),
                                        type: "text" as const,
                                        placeholder: getI18nLocaleObject(namespaceList.admin, "placeholderForEmptyText"),
                                    },
                                ],
                                visible: (options: WidgetOptions) => !!options.nrOfSearchResultsByDefault && !!options.useToggleFilter && options.displayType === "displayAsList",
                            }),
                            {
                                variable: "contentTypes",
                                type: "multiselect",
                                label: getI18nLocaleObject(namespaceList.dynamicPlugin, "contentType"),
                                optionList: () => [
                                    {
                                        value: ContentType.RESORT,
                                        text: I18nRESORT,
                                    },
                                    {
                                        value: ContentType.REGION,
                                        text: I18nREGION,
                                    },
                                    {
                                        value: ContentType.ACCOMMODATION_KIND,
                                        text: I18nACCOMMODATIONKIND,
                                    },
                                    {
                                        value: ContentType.AMENITY,
                                        text: I18nAMENITY,
                                    },
                                ],
                                visible: (options: WidgetOptions) => !options.useCustomFreeSearch,
                            },
                            {
                                variable: "showRegionsBasedOnAvailability",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.searchAmenities, "showRegionsBasedOnAvailability"),
                                visible: (options: WidgetOptions) =>
                                    !options.useCustomFreeSearch && !!options.contentTypes.length && options.contentTypes.some((contentType) => contentType.value === ContentType.REGION),
                            },
                            {
                                variable: "isPriorityBasedRegionOrder",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.searchAmenities, "isPriorityBasedRegionOrder"),
                                visible: (options: WidgetOptions) =>
                                    !options.useCustomFreeSearch && !!options.contentTypes.length && options.contentTypes.some((contentType) => contentType.value === ContentType.REGION),
                            },
                            {
                                variable: "showUnavailableRegions",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.searchAmenities, "showUnavailableRegions"),
                                visible: (options: WidgetOptions) =>
                                    !options.useCustomFreeSearch && !!options.contentTypes.length && options.contentTypes.some((contentType) => contentType.value === ContentType.REGION),
                            },
                            {
                                variable: "resorts",
                                type: "multiselect",
                                label: getI18nLocaleObject(namespaceList.dynamicPlugin, "resort"),
                                optionList: async () => resortOptions(MXTS.MxtsApi),
                                visible: (options: WidgetOptions) =>
                                    !options.useCustomFreeSearch && options.contentTypes.length > 0 && options.contentTypes.some((contentType) => contentType.value === ContentType.RESORT),
                            },
                            {
                                variable: "resortIcon",
                                label: getI18nLocaleObject(namespaceList.admin, "resortIconProperty"),
                                type: "select",
                                optionList: iconList,
                                visible: (options: WidgetOptions) =>
                                    !options.useCustomFreeSearch &&
                                    options.contentTypes.length > 0 &&
                                    options.contentTypes.some((contentType) => contentType.value === ContentType.RESORT) &&
                                    options.displayType === "displayAsDropdown",
                            },
                            {
                                variable: "resortIcon",
                                type: "icons",
                                visible: (options: WidgetOptions) =>
                                    !!options.resortIcon &&
                                    !options.useCustomFreeSearch &&
                                    options.contentTypes.length > 0 &&
                                    options.contentTypes.some((contentType) => contentType.value === ContentType.RESORT) &&
                                    options.displayType === "displayAsDropdown",
                            },
                            {
                                variable: "regions",
                                type: "lazyLoadMultiSelect",
                                label: getI18nLocaleObject(namespaceList.dynamicPlugin, "region"),
                                lazyLoadOptions: (page: number, searchQuery: string, regionIds: string[] | undefined) => lazyLoadRegionOptions(page, searchQuery, regionIds),
                                visible: (options: WidgetOptions) =>
                                    !options.useCustomFreeSearch && options.contentTypes.length > 0 && options.contentTypes.some((contentType) => contentType.value === ContentType.REGION),
                            },
                            {
                                variable: "regionIcon",
                                label: getI18nLocaleObject(namespaceList.admin, "regionIconProperty"),
                                type: "select",
                                optionList: iconList,
                                visible: (options: WidgetOptions) =>
                                    !options.useCustomFreeSearch &&
                                    options.contentTypes.length > 0 &&
                                    options.contentTypes.some((contentType) => contentType.value === ContentType.REGION) &&
                                    options.displayType === "displayAsDropdown",
                            },
                            {
                                variable: "regionIcon",
                                type: "icons",
                                visible: (options: WidgetOptions) =>
                                    !!options.regionIcon &&
                                    !options.useCustomFreeSearch &&
                                    options.contentTypes.length > 0 &&
                                    options.contentTypes.some((contentType) => contentType.value === ContentType.REGION) &&
                                    options.displayType === "displayAsDropdown",
                            },
                            {
                                variable: "accoKinds",
                                type: "multiselect",
                                label: getI18nLocaleObject(namespaceList.dynamicPlugin, "accoKind"),
                                optionList: async () => accoKindOptions(MXTS.MxtsApi),
                                visible: (options: WidgetOptions) =>
                                    !options.useCustomFreeSearch && options.contentTypes.length > 0 && options.contentTypes.some((contentType) => contentType.value === ContentType.ACCOMMODATION_KIND),
                            },
                            {
                                variable: "accoKindIcon",
                                label: getI18nLocaleObject(namespaceList.admin, "accoKindIconProperty"),
                                type: "select",
                                optionList: iconList,
                                visible: (options: WidgetOptions) =>
                                    !options.useCustomFreeSearch &&
                                    options.contentTypes.length > 0 &&
                                    options.contentTypes.some((contentType) => contentType.value === ContentType.ACCOMMODATION_KIND) &&
                                    options.displayType === "displayAsDropdown",
                            },
                            {
                                variable: "accoKindIcon",
                                type: "icons",
                                visible: (options: WidgetOptions) =>
                                    !!options.accoKindIcon &&
                                    !options.useCustomFreeSearch &&
                                    options.contentTypes.length > 0 &&
                                    options.contentTypes.some((contentType) => contentType.value === ContentType.ACCOMMODATION_KIND) &&
                                    options.displayType === "displayAsDropdown",
                            },
                            {
                                variable: "amenities",
                                type: "multiselect",
                                label: getI18nLocaleObject(namespaceList.dynamicPlugin, "amenity"),
                                optionList: async () => getAmenitiesAutocompleteList(MXTS.MxtsApi),
                                visible: (options: WidgetOptions) =>
                                    !options.useCustomFreeSearch && options.contentTypes.length > 0 && options.contentTypes.some((contentType) => contentType.value === ContentType.AMENITY),
                            },
                            {
                                variable: "amenityIcon",
                                label: getI18nLocaleObject(namespaceList.admin, "amenintyIconProperty"),
                                type: "select",
                                optionList: iconList,
                                visible: (options: WidgetOptions) =>
                                    !options.useCustomFreeSearch &&
                                    options.contentTypes.length > 0 &&
                                    options.contentTypes.some((contentType) => contentType.value === ContentType.AMENITY) &&
                                    options.displayType === "displayAsDropdown",
                            },
                            {
                                variable: "amenityIcon",
                                type: "icons",
                                visible: (options: WidgetOptions) =>
                                    !!options.amenityIcon &&
                                    !options.useCustomFreeSearch &&
                                    options.contentTypes.length > 0 &&
                                    options.contentTypes.some((contentType) => contentType.value === ContentType.AMENITY) &&
                                    options.displayType === "displayAsDropdown",
                            },
                            {
                                variable: "fetchCount",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.admin, "fetchCount"),
                                visible: (options: WidgetOptions) => options.useCustomFreeSearch,
                            },
                            {
                                variable: "freeSearchItems",
                                label: getI18nLocaleObject(namespaceList.admin, "freeSearchItems"),
                                type: "multiselect",
                                optionList: async (): Promise<Array<{ text: string; value: string }>> =>
                                    FreeSearchApi.find({ projection: { name: 1 } }).then((result) => result.map((freeSearchItem) => ({ value: freeSearchItem._id, text: freeSearchItem.name }))),
                                visible: (options: WidgetOptions) => options.useCustomFreeSearch,
                            },
                            {
                                variable: "isMultiSelect",
                                type: "checkbox",
                                label: getI18nLocaleObject(namespaceList.dynamicPlugin, "isMultiSelect"),
                                visible: (options: WidgetOptions) => options.displayType === "displayAsDropdown",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "styling"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),
                            {
                                variable: "highlightInput",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "highlightInput"),
                                type: "checkbox",
                                default: false,
                                groupName: "highlightInputGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.admin, "highlightInputGroupTitle"),
                            },
                            {
                                variable: "highlightColor",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "highlightColor"),
                                type: "dual-color",
                                default: "theme-ctaColor",
                                groupName: "highlightInputGroup",
                                visible: (options: WidgetOptions) => options.highlightInput,
                            },
                        ],
                    ],
                },
            ],
        },
    ],
};

function isInsidePolygon(point: number[], polygon: number[][]) {
    const x = point[0];
    const y = point[1];

    let isInside = false;
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        const xi = polygon[i][0];
        const yi = polygon[i][1];
        const xj = polygon[j][0];
        const yj = polygon[j][1];

        const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect) {
            isInside = !isInside;
        }
    }
    return isInside;
}

export const dynamicFreeSearchWidget: PageWidgetSpec<WidgetOptions> = {
    id: "dynamic-free-search",
    type: "page",
    widgetGroup: WidgetGroup ? WidgetGroup.DYNAMIC : 2,
    name: getI18nLocaleObject(namespaceList.admin, "dynamicFreeSearchWidget"),
    description: getI18nLocaleObject(namespaceList.admin, "dynamicFreeSearchWidgetOptions"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        styleIds: [],
        placeholder: "",
        freeSearchItems: [],
        fetchCount: false,
        useCustomFreeSearch: false,
        contentTypes: [],
        accoKinds: [],
        accoKindIcon: "",
        amenities: [],
        amenityIcon: "",
        regions: [],
        regionIcon: "",
        resorts: [],
        resortIcon: "",
        highlightInput: false,
        highlightColor: "theme-ctaColor",
        displayType: "displayAsDropdown",
        nrOfSearchResultsByDefault: 5,
        useToggleFilter: false,
        localizedContent: [],
    }),
    container: false,
    // eslint-disable-next-line max-lines-per-function
    async render(widget: Widget<WidgetOptions>, context: CMSProvidedProperties) {
        let freeSearchItems: FreeSearchData[] = [];
        const currentEnv = await getMxtsEnv(context, context.currentLocale.code);
        if (widget.options.useCustomFreeSearch) {
            const itemIds = widget.options.freeSearchItems.map((item) => item.value.toString());
            let freeSearchResults = await FreeSearchApi.findMany({ ids: itemIds });
            freeSearchResults = itemIds.map((id) => freeSearchResults.find((result) => result._id === id)!);
            if (widget.options.fetchCount) {
                if (context.distributionChannelId) {
                    const ops = await getMxtsEnv(context);
                    const baseFilter = {
                        application: APPLICATION,
                        distributionChannelId: context.distributionChannelId,
                        releaseStatus: "OPEN" as const,
                        aggregations: [UNITS_WITH_PRICE_AGGREGATION, ALL_AMENITIES_AGGREGATION],
                    };
                    const availability = await getAvailability(context, ops, baseFilter);
                    const updatedFreeSearchItemsPromises = freeSearchResults.map(async (item) => {
                        let amenityFilters: number[] = [];
                        let regionFilters: number[] = [];
                        let resortFilters: number[] = [];
                        let accoKindFilters: number[] = [];
                        let regions: MXTS.Region[] = [];
                        if (item.amenity && item.amenity.length > 0) {
                            amenityFilters = item.amenity.map((amenity) => amenity.value);
                        }
                        if (item.region && item.region.length > 0) {
                            regionFilters = item.region.map((region) => region.value);
                            const regionPromises = regionFilters.map((region) => MXTS.MxtsApi.region(ops, {}, [{ key: "regionId", value: region }]));
                            regions = await Promise.all(regionPromises);
                        }
                        if (item.resort && item.resort.length > 0) {
                            resortFilters = item.resort.map((resort) => resort.value);
                        }
                        if (item.accoKind && item.accoKind.length > 0) {
                            accoKindFilters = item.accoKind.map((accoKind) => accoKind.value);
                        }
                        let count = 0;
                        availability.response.units!.forEach((unit) => {
                            let length = 0;
                            if (amenityFilters.length > 0) {
                                length = amenityFilters.every((filter) => availability.response.amenities.some((amenity) => amenity.key === filter)) ? length + 1 : length;
                            }
                            if (accoKindFilters.length > 0) {
                                length = accoKindFilters.some((filter) => filter === unit.accommodationkindId) ? length + 1 : length;
                            }
                            if (resortFilters.length > 0) {
                                length = resortFilters.some((filter) => filter === unit.resortId) ? length + 1 : length;
                            }
                            if (regionFilters.length > 0) {
                                length = regions.some((filter) => {
                                    const polygon = filter.geoShapePoints[0].map((point: any) => [point.latitude, point.longitude]);
                                    return unit.unitLocation ? isInsidePolygon([unit.unitLocation.lat, unit.unitLocation.lon], polygon) : false;
                                })
                                    ? length + 1
                                    : length;
                            }
                            if (item.contentType && length === item.contentType.length && unit.choosableOnInternet) {
                                count++;
                            }
                        });
                        return {
                            name: item.name,
                            icon: item.icon,
                            resortId: resortFilters,
                            regionId: regionFilters,
                            accoKindId: accoKindFilters,
                            amenities: amenityFilters,
                            subtitle: item.subtitle,
                            searchString: item.searchString || item.name,
                            isDefault: item.isDefault,
                            nrOfResults: count,
                            contentType: (item.contentType as any) as NumberMultiSelectOption[],
                        };
                    });
                    freeSearchItems = await Promise.all(updatedFreeSearchItemsPromises);
                }
            } else {
                const ops = await getMxtsEnv(context);
                freeSearchItems = await Promise.all(
                    freeSearchResults.map(async (item) => {
                        if (widget.options.showParentAndChildRegionsInDropdown && item.region) {
                            const regionChildren = await Promise.all(
                                item.region.map(async (reg) => {
                                    const children = (await MXTS.MxtsApi.getRegionChildren(ops, {}, [{ key: "regionId", value: reg.value }])).content.map((child) => ({
                                        name: child.name,
                                        icon: item.icon,
                                        regionId: [child.regionId],
                                        parentId: child.parentId,
                                        searchString: child.name,
                                        isDefault: item.isDefault,
                                        contentType: (item.contentType as any) as NumberMultiSelectOption[],
                                    }));
                                    return children;
                                })
                            );
                            return {
                                name: item.name,
                                icon: item.icon,
                                resortId: item.resort && item.resort.map((res) => res.value),
                                regionId: item.region && item.region.map((reg) => reg.value),
                                childRegions: regionChildren.flat().length ? regionChildren.flat() : undefined,
                                accoKindId: item.accoKind && item.accoKind.map((acco) => acco.value),
                                amenities: item.amenity && item.amenity.map((am) => am.value),
                                subtitle: item.subtitle,
                                searchString: item.searchString || item.name,
                                isDefault: item.isDefault,
                                contentType: (item.contentType as any) as NumberMultiSelectOption[],
                            };
                        }
                        return {
                            name: item.name,
                            icon: item.icon,
                            resortId: item.resort && item.resort.map((res) => res.value),
                            regionId: item.region && item.region.map((reg) => reg.value),
                            accoKindId: item.accoKind && item.accoKind.map((acco) => acco.value),
                            amenities: item.amenity && item.amenity.map((am) => am.value),
                            subtitle: item.subtitle,
                            searchString: item.searchString || item.name,
                            isDefault: item.isDefault,
                            contentType: (item.contentType as any) as NumberMultiSelectOption[],
                        };
                    })
                );
            }
        } else {
            const fetchFreeSearchItems = widget.options.contentTypes.map((contentType) => {
                const availableRegions = context.reduxStore.store.getState().availabilityState.availabilityResult?.response.regions;
                const resorts = getFreeSearchItems(ItemType.RESORTS, currentEnv, widget.options);
                const regions = getFreeSearchItems(ItemType.REGIONS, currentEnv, widget.options, availableRegions);
                const accoKinds = getFreeSearchItems(ItemType.ACCOMMODATION_KINDS, currentEnv, widget.options);
                const amenities = getFreeSearchItems(ItemType.AMENITIES, currentEnv, widget.options);

                switch (contentType.value) {
                    case ContentType.RESORT:
                        return resorts;
                    case ContentType.REGION:
                        return regions;
                    case ContentType.ACCOMMODATION_KIND:
                        return accoKinds;
                    case ContentType.AMENITY:
                        return amenities;
                    default:
                        return [];
                }
            });

            freeSearchItems = await Promise.all(fetchFreeSearchItems).then((results) => results.flat());
        }
        // Arrange regions as configured
        let arrangedFreeSearchItems: FreeSearchData[] = [];
        widget.options.contentTypes.map((contentType) => {
            switch (contentType.value) {
                case ContentType.RESORT:
                    widget.options.resorts.forEach((resort) => {
                        const foundFreeSearchItem = freeSearchItems.find((freeSearchItem) => freeSearchItem.resortId?.includes(resort.value));
                        if (foundFreeSearchItem) {
                            arrangedFreeSearchItems.push(foundFreeSearchItem);
                        }
                    });
                    break;
                case ContentType.REGION:
                    if (widget.options.regions.length) {
                        widget.options.regions.forEach((region) => {
                            const foundFreeSearchItem = freeSearchItems.find((freeSearchItem) => freeSearchItem.regionId?.includes(region));
                            if (foundFreeSearchItem) {
                                arrangedFreeSearchItems.push(foundFreeSearchItem);
                            }
                        });
                    } else if (widget.options.showRegionsBasedOnAvailability) {
                        if (widget.options.showUnavailableRegions) {
                            arrangedFreeSearchItems = [...arrangedFreeSearchItems, ...freeSearchItems];
                        } else {
                            context.reduxStore.store.getState().availabilityState.availabilityResult?.response.regions?.forEach((region) => {
                                const foundFreeSearchItem = freeSearchItems.find((freeSearchItem) => freeSearchItem.regionId?.includes(Number(region.key)));
                                if (foundFreeSearchItem) {
                                    arrangedFreeSearchItems.push(foundFreeSearchItem);
                                }
                            });
                        }
                    }
                    break;
                case ContentType.ACCOMMODATION_KIND:
                    widget.options.accoKinds.forEach((accoKind) => {
                        const foundFreeSearchItem = freeSearchItems.find((freeSearchItem) => freeSearchItem.accoKindId?.includes(accoKind.value));
                        if (foundFreeSearchItem) {
                            arrangedFreeSearchItems.push(foundFreeSearchItem);
                        }
                    });
                    break;
                case ContentType.AMENITY:
                    const foundFreeSearchItem = freeSearchItems.filter((freeSearchItem) => freeSearchItem.amenities);
                    if (foundFreeSearchItem) {
                        arrangedFreeSearchItems.push(...foundFreeSearchItem);
                    }
                    break;
                default:
                    return [];
            }
        });
        if (widget.options.useCustomFreeSearch) {
            arrangedFreeSearchItems = freeSearchItems;
        }
        const className = findMultiSelectStyleClassNames(context.theme, TARGETS, widget.options.styleIds);
        return <DynamicFreeSearch className={className} options={widget.options} allFreeSearchData={arrangedFreeSearchItems} context={context} />;
    },
};
