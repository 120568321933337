import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";

import { AvailabilityRequest, ElasticFacetV2 } from "@maxxton/cms-mxts-api";
import { FreeSearchData, FreeSearchSelectItem } from "./dynamicFreeSearch.types";
import { ItemType, getFreeSearchItems, isWidgetHidden, setOpacityOnHide } from "../../../components/utils";
import Select, { ActionMeta, OptionProps, ValueContainerProps, ValueType, components } from "react-select";
import { useDispatch, useSelector } from "react-redux";

import { ActionType } from "../../../redux/actions";
import { BoxModelUtil } from "../../../utils/boxmodel.util";
import { CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { ChangeEventExtra } from "rc-tree-select/lib/TreeSelect";
import { Collapse } from "reactstrap";
import { ContentType } from "../../../components/components.enum";
import { Dispatch } from "redux";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { FilterChangeAction } from "../../../redux/actions/dynamicFilterAction.types";
import { State } from "../../../redux";
import TreeSelect from "rc-tree-select";
import { WidgetOptions } from "./";
import { dispatchNow } from "../../../utils/redux.util";
import { dynamicFilterType } from "../../../redux/reducers/dynamicFilter.enum";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { getMxtsEnv } from "../../mxts";
import { isClientSide } from "../../../utils/generic.util";
import { isEqual } from "lodash";
import makeAnimated from "react-select/animated";
import { sortByPriority } from "../../../utils/sortingPanel.util";

const { Option } = components;

interface DynamicFreeSearchProps {
    className: string;
    options: WidgetOptions;
    allFreeSearchData: FreeSearchData[];
    context: CMSProviderProperties;
}

interface SelectCallbackAction {
    action: "remove-value" | "select-option" | "clear" | "pop-value" | "deselect-option";
    name: string;
    removedValues?: FreeSearchSelectItem[];
    option?: FreeSearchSelectItem;
    removedValue?: FreeSearchSelectItem;
}

const IconOption = (props: OptionProps<FreeSearchSelectItem, true, any>) => (
    <Option {...props}>
        <FontAwesome name={props.data.freeSearchData.icon} className="mr-2" />
        <span className="title">{props.data.label}</span>
        {props.data.freeSearchData.subtitle && <span className="subtitle">{props.data.freeSearchData.subtitle}</span>}
        {props.data.freeSearchData.nrOfResults && <span className="nr-of-results">{props.data.freeSearchData.nrOfResults}</span>}
    </Option>
);

const animatedComponents = makeAnimated({ Option: IconOption });

// eslint-disable-next-line max-lines-per-function
export const DynamicFreeSearch = (props: DynamicFreeSearchProps) => {
    const { options, allFreeSearchData, className, context } = props;
    const [allUpdatedFreeSearchData, setAllUpdatedFreeSearchData] = React.useState<FreeSearchData[]>(allFreeSearchData);
    const { currentLocale, site } = context;
    const { highlightColor, highlightInput, placeholder, isMultiSelect, isPriorityBasedRegionOrder } = options;
    const isMultiSelectDropdown = options.displayType === "displayAsDropdown" ? isMultiSelect : false;
    const hideWidget = setOpacityOnHide(options);
    const mapRegions = (freeSearchData: FreeSearchData, idOffset = 0): FreeSearchSelectItem => ({
        value: freeSearchData.searchString,
        label: freeSearchData.name,
        freeSearchData: { ...freeSearchData, id: idOffset + 1 },
        children: freeSearchData.childRegions?.map((childRegion, childIndex) => mapRegions(childRegion, childIndex)) || [],
    });

    const allFreeSearchItems: FreeSearchSelectItem[] = allUpdatedFreeSearchData
        .map((freeSearchData: FreeSearchData, index: number) => {
            if (options.showParentAndChildRegionsInDropdown && options.showMultiLevelDropdown) {
                return mapRegions(freeSearchData, index);
            } else if (options.showParentAndChildRegionsInDropdown && !options.showMultiLevelDropdown) {
                return {
                    value: freeSearchData.searchString,
                    label: freeSearchData.name,
                    freeSearchData: { ...freeSearchData, id: index + 1 },
                    options: freeSearchData.childRegions?.length
                        ? freeSearchData.childRegions
                              ?.map((childRegion, childIndex) => ({
                                  value: childRegion.searchString,
                                  label: childRegion.name,
                                  freeSearchData: { ...childRegion, id: childIndex + 1 },
                              }))
                              .sort((a, b) => a.value.localeCompare(b.value))
                        : [
                              {
                                  value: "",
                                  label: "",
                                  freeSearchData: { icon: "", name: "", searchString: "" },
                              },
                          ],
                };
            }
            return {
                value: freeSearchData.searchString,
                label: freeSearchData.name,
                freeSearchData: { ...freeSearchData, id: index + 1 },
            };
        })
        .sort((a, b) => a.value.localeCompare(b.value));
    if (isPriorityBasedRegionOrder) {
        sortByPriority(allFreeSearchItems);
        // Sorting the childRegion inside each Region based on priority
        allFreeSearchItems.forEach((item) => {
            if (item.options && Array.isArray(item.options)) {
                sortByPriority(item.options);
            }
        });
    }
    const [selectedItems, setSelectedItems] = React.useState<FreeSearchSelectItem[]>([]);
    const [showMore, setShowMore] = React.useState<boolean>(false);
    const [filteredItems, setFilteredItems] = React.useState<FreeSearchSelectItem[]>(allFreeSearchItems);
    const [selectedCollapsableItems, setSelectedCollapsableItems] = React.useState<Array<{ key: number; isCollapseOpen: boolean }>>([]);
    const dynamicFilter = useSelector((state: State) => state.dynamicFilter);
    const availabilityRequest = useSelector((state: State) => state.availabilityState.availabilityRequest);
    delete availabilityRequest?.regionId;
    delete availabilityRequest?.regionIds;
    const [filteredAvailabilityRequest, setFilteredAvailabilityRequest] = React.useState<AvailabilityRequest | undefined>(availabilityRequest);
    const availableRegions = useSelector((state: State) => state.availabilityState.availabilityResult?.response.regions);
    const [uniqueAvailableRegions, setUniqueAvailableRegions] = React.useState<number[]>(Array.from(new Set(availableRegions?.map((region) => Number(region.key)))));
    const dispatch = useDispatch();
    const currentLocalized = options?.localizedContent && getLocalizedContent({ currentLocale, site, localizedContent: options.localizedContent });
    const onSelectItem = React.useCallback(
        (selection: FreeSearchSelectItem | FreeSearchSelectItem[], action: SelectCallbackAction) => {
            const newSelectedItems = Array.isArray(selection) ? selection : (selection && [selection]) || [];
            const removedItems = action.removedValues || (action.removedValue ? [action.removedValue] : []);
            if (!isMultiSelectDropdown) {
                dispatchFilterChange({ dispatch, selectedItems: newSelectedItems, removedItems: selectedItems, isMultiSelectDropdown: !!isMultiSelectDropdown });
            }
            setSelectedItems(newSelectedItems);
            dispatchFilterChange({ dispatch, selectedItems: newSelectedItems, removedItems, isMultiSelectDropdown: !!isMultiSelectDropdown });
        },
        [dispatch, isMultiSelectDropdown, selectedItems]
    ) as (selectedItems: ValueType<FreeSearchSelectItem, true>, action: ActionMeta<FreeSearchSelectItem>) => void;
    React.useEffect(() => {
        if (isClientSide()) {
            setSelectedItems(getSelectedItemsFromDynamicFilter({ options, allFreeSearchItems, dynamicFilter }));
        }
    }, [dynamicFilter.freeSearchId, dynamicFilter.regionIds, dynamicFilter.amenities, dynamicFilter.resortids, dynamicFilter.accokindids]);

    React.useEffect(() => {
        if (options.nrOfSearchResultsByDefault) {
            setFilteredItems(allFreeSearchItems.slice(0, options.nrOfSearchResultsByDefault));
        }
    }, []);

    React.useEffect(() => {
        if (options.showRegionsBasedOnAvailability && availableRegions && options.contentTypes.some((contentType) => contentType.value === ContentType.REGION) && !options.showUnavailableRegions) {
            setUpdatedDataFromAvalability({ context, widgetOptions: options, uniqueAvailableRegions, availableRegions, setAllUpdatedFreeSearchData });
        }
    }, [filteredAvailabilityRequest]);

    React.useEffect(() => {
        if (options.showRegionsBasedOnAvailability) {
            setFilteredAvailabilityRequest((prevState) => {
                if (isEqual(prevState, availabilityRequest)) {
                    return prevState;
                }
                setUniqueAvailableRegions(Array.from(new Set(availableRegions?.map((region) => Number(region.key)))));
                return availabilityRequest;
            });
        }
    }, [availabilityRequest]);

    const getSelectedItems = (data: FreeSearchSelectItem) => {
        onSelectItem(selectedItems.find((item) => item.value === data.value) ? selectedItems.filter((item) => item.value !== data.value) : [...selectedItems, data], {
            action: "select-option",
            name: "freeSearch",
            option: data,
        });
    };
    const handleToggleFilter = () => {
        setShowMore(!showMore);
        if (!showMore) {
            setFilteredItems(allFreeSearchItems);
        } else if (options.nrOfSearchResultsByDefault && showMore) {
            setFilteredItems(allFreeSearchItems.slice(0, options.nrOfSearchResultsByDefault));
        }
    };

    const toggleCollapse = (key: number) => {
        if (selectedCollapsableItems.some((selectedCollapsableItem) => selectedCollapsableItem.key === key)) {
            const collapsableItems = [...selectedCollapsableItems];
            const itemIndex = selectedCollapsableItems.findIndex((selectedCollapsableItem) => selectedCollapsableItem.key === key);
            collapsableItems[itemIndex].isCollapseOpen = !collapsableItems[itemIndex].isCollapseOpen;
            setSelectedCollapsableItems(collapsableItems);
        } else {
            const collapsableItems = [...selectedCollapsableItems, { key, isCollapseOpen: true }];
            setSelectedCollapsableItems(collapsableItems);
        }
    };

    const ValueContainer = ({ children, ...props }: ValueContainerProps<FreeSearchSelectItem, true>) => {
        const { getValue, hasValue, isMulti } = props;
        const numberOfValues = getValue().length;
        if (!hasValue || !isMulti) {
            return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
        }
        return (
            <components.ValueContainer {...props}>
                {
                    <div className="select__multi-value">
                        <div className="select__multi-value__label">{`${getValue()[0].label}${numberOfValues > 1 ? `+ ${numberOfValues} ` : ""}`}</div>
                    </div>
                }
            </components.ValueContainer>
        );
    };

    function stopDropdownCloseOnRegionSelect(event: React.MouseEvent<HTMLLabelElement, MouseEvent>, selectedData: FreeSearchSelectItem, isSelected: boolean, selectedDataType: "parent" | "child") {
        event.preventDefault?.();
        if (selectedDataType === "parent") {
            handleParentCheckboxChange(selectedData, !isSelected);
        } else {
            handleChildCheckboxChange(selectedData, !isSelected);
        }
    }

    const CustomCheckboxParentOption = (props: OptionProps<FreeSearchSelectItem, true, any>) => {
        const { data } = props;
        const regionKey = data.freeSearchData.regionId[0];
        const disableCheckBoxOption = options.showRegionsBasedOnAvailability ? !uniqueAvailableRegions?.some((region) => region === regionKey) : false;
        const isSelected =
            !!(data as FreeSearchSelectItem)?.options?.every((childOptions) => selectedItems.some((selectedItem) => selectedItem.value === childOptions.value)) ||
            !!selectedItems.some((selectedItem) => selectedItem.value === (data as FreeSearchSelectItem).value);
        return (
            <div className={`parent-region ${disableCheckBoxOption ? "ownuse-disable" : ""} ${data.options?.[0]?.value.length ? "child-dropdown" : ""}`}>
                <label className="parent-region-label" htmlFor={regionKey} key={regionKey} onClick={(event) => stopDropdownCloseOnRegionSelect(event, data, isSelected, "parent")}>
                    <input
                        type="checkbox"
                        name="parent-free-search"
                        value={data.value}
                        checked={disableCheckBoxOption ? false : isSelected}
                        onChange={(event) => handleParentCheckboxChange(data, event.target.checked)}
                        className="parent-region-input"
                        id={regionKey}
                        disabled={disableCheckBoxOption}
                    />
                    {data.label}
                </label>
                {!!data.options?.[0]?.value.length && (
                    <span className="collapsible-icon" onClick={() => toggleCollapse(regionKey)}>
                        <FontAwesome name={selectedCollapsableItems.find((selectedCollapsableItem) => selectedCollapsableItem.key === regionKey)?.isCollapseOpen ? "caret-up" : "caret-down"} />
                    </span>
                )}
            </div>
        );
    };

    const CustomCheckboxChildOption = (props: OptionProps<FreeSearchSelectItem, true, any>) => {
        const { data } = props;
        const childParentKey = data.freeSearchData.parentId;
        const childKey = data.freeSearchData.regionId?.[0];
        const disableCheckBoxOption = options.showRegionsBasedOnAvailability ? !uniqueAvailableRegions?.some((region) => region === childKey) : false;
        const isChecked = isMultiSelectDropdown
            ? selectedItems.some(({ freeSearchData, value }) => freeSearchData.regionId?.[0] === data.freeSearchData.parentId || value === data.value)
            : selectedItems.some(({ value }) => value === data.value);
        return (data as FreeSearchSelectItem).value.length ? (
            <Collapse key={data.value} isOpen={selectedCollapsableItems.find((selectedCollapsableItem) => selectedCollapsableItem.key === childParentKey)?.isCollapseOpen}>
                <div className={`child-region ${disableCheckBoxOption ? "ownuse-disable" : ""}`}>
                    <label
                        className="child-region-label"
                        htmlFor={data.freeSearchData.regionId[0]}
                        key={data.freeSearchData.regionId[0]}
                        onClick={(event) => stopDropdownCloseOnRegionSelect(event, data, isChecked || selectedItems.some((selectedItem) => selectedItem.value === data.value), "child")}
                    >
                        <input
                            type="checkbox"
                            name="child-free-search"
                            value={data.value}
                            checked={disableCheckBoxOption ? false : isChecked}
                            className="child-region-input"
                            onChange={(event) => handleChildCheckboxChange(data, event.target.checked)}
                            id={data.freeSearchData.regionId[0]}
                            disabled={disableCheckBoxOption}
                        />
                        {data.label}
                    </label>
                </div>
            </Collapse>
        ) : (
            <div />
        );
    };

    const handleParentCheckboxChange = (parentOption: FreeSearchSelectItem, checked: boolean) => {
        let updateSelectedValues = [...selectedItems, parentOption];
        const availableChildRegions = options.showRegionsBasedOnAvailability
            ? parentOption.options?.filter((childRegion) => uniqueAvailableRegions?.some((region) => region === childRegion.freeSearchData.regionId?.[0]))
            : parentOption.options;
        if (checked) {
            if (
                isMultiSelectDropdown &&
                availableChildRegions?.some((childRegion) => updateSelectedValues.some((selectedRegion) => selectedRegion.freeSearchData.regionId === childRegion.freeSearchData.regionId))
            ) {
                updateSelectedValues = [...updateSelectedValues].filter((updatedValue) =>
                    availableChildRegions.every((childRegion) => childRegion.freeSearchData.regionId !== updatedValue.freeSearchData.regionId)
                );
                availableChildRegions?.forEach((childRegion) => {
                    onSelectItem(updateSelectedValues, {
                        action: "remove-value",
                        name: "freeSearch",
                        removedValue: childRegion,
                    });
                });
            }
            onSelectItem(updateSelectedValues, {
                action: "select-option",
                name: "freeSearch",
                option: parentOption,
            });
            if (!isMultiSelectDropdown && selectedItems.length) {
                updateSelectedValues = [];
                onSelectItem(updateSelectedValues, {
                    action: "remove-value",
                    name: "freeSearch",
                    removedValue: selectedItems[0],
                });
            }
        } else {
            updateSelectedValues = selectedItems.filter((item) => item.value !== parentOption.value).filter((item) => !parentOption.options?.some((option) => option.value === item.value));
            onSelectItem(updateSelectedValues, {
                action: "remove-value",
                name: "freeSearch",
                removedValue: parentOption,
            });
        }
    };

    const handleChildCheckboxChange = (childOption: FreeSearchSelectItem, checked: boolean) => {
        let updatedSelectedValues: FreeSearchSelectItem[];
        if (checked) {
            updatedSelectedValues = [...selectedItems, childOption];
            const parentRegion = allFreeSearchItems.find((allFreeSearchItem) => allFreeSearchItem.freeSearchData.regionId?.[0] === childOption.freeSearchData.parentId);
            if (
                isMultiSelectDropdown &&
                parentRegion &&
                (options.showRegionsBasedOnAvailability
                    ? parentRegion.options?.filter((childRegion) => uniqueAvailableRegions?.some((region) => region === childRegion.freeSearchData.regionId?.[0]))
                    : parentRegion.options
                )?.every((option) => updatedSelectedValues.some((updatedSelectedValue) => updatedSelectedValue.freeSearchData.regionId === option.freeSearchData.regionId))
            ) {
                updatedSelectedValues = [...updatedSelectedValues, parentRegion].filter((updatedValue) =>
                    parentRegion.freeSearchData.childRegions?.every((childRegion) => childRegion.regionId !== updatedValue.freeSearchData.regionId)
                );
                parentRegion.options?.forEach((option) => {
                    onSelectItem(updatedSelectedValues, {
                        action: "remove-value",
                        name: "freeSearch",
                        removedValue: option,
                    });
                });
            }
            onSelectItem(updatedSelectedValues, {
                action: "select-option",
                name: "freeSearch",
                option: childOption,
            });
            if (!isMultiSelectDropdown && selectedItems.length) {
                updatedSelectedValues = [];
                onSelectItem(updatedSelectedValues, {
                    action: "remove-value",
                    name: "freeSearch",
                    removedValue: selectedItems[0],
                });
            }
        } else {
            const selectedParent = selectedItems.find((item) => item.freeSearchData.regionId?.[0] === childOption.freeSearchData.parentId);
            if (selectedParent) {
                const remainingChilderen = (options.showRegionsBasedOnAvailability
                    ? selectedParent.options?.filter((childRegion) => uniqueAvailableRegions?.some((region) => region === childRegion.freeSearchData.regionId?.[0]))
                    : selectedParent.options
                )?.filter((option) => option.value !== childOption.value);
                if (remainingChilderen?.length) {
                    updatedSelectedValues = [...selectedItems, ...remainingChilderen].filter((item) => item.value !== selectedParent.value);
                    onSelectItem(updatedSelectedValues, {
                        action: "remove-value",
                        name: "freeSearch",
                        removedValue: selectedParent,
                    });
                    onSelectItem(updatedSelectedValues, {
                        action: "select-option",
                        name: "freeSearch",
                        option: childOption,
                    });
                } else {
                    updatedSelectedValues = selectedItems.filter((item) => item.value !== selectedParent.value);
                    onSelectItem(updatedSelectedValues, {
                        action: "remove-value",
                        name: "freeSearch",
                        removedValue: selectedParent,
                    });
                }
            } else {
                updatedSelectedValues = selectedItems.filter((item) => item.value !== childOption.value);
                onSelectItem(updatedSelectedValues, {
                    action: "remove-value",
                    name: "freeSearch",
                    removedValue: childOption,
                });
            }
        }
    };

    if (isWidgetHidden(options)) {
        return null;
    }
    function handleTreeOnChange(value: FreeSearchSelectItem[], labelList: React.ReactNode[], extra: ChangeEventExtra) {
        const selectRegion: FreeSearchSelectItem = extra.triggerNode.props;
        const alreadySelectedRegion: FreeSearchSelectItem[] = extra.allCheckedNodes.map((node) => node.node?.props || (node as any).props);
        if (extra.checked) {
            const preSelectedValues: FreeSearchSelectItem[] = extra.preValue as FreeSearchSelectItem[];
            if (preSelectedValues.length) {
                preSelectedValues.forEach((preSelectedValue) => {
                    onSelectItem(alreadySelectedRegion, {
                        action: "remove-value",
                        name: "freeSearch",
                        removedValue: preSelectedValue,
                    });
                });
            }
            onSelectItem(alreadySelectedRegion, {
                action: "select-option",
                name: "freeSearch",
                option: selectRegion,
            });
        } else {
            const deletedValue = selectedItems.find((selectedItem) => alreadySelectedRegion.every((region) => region.value !== selectedItem.value));
            if (alreadySelectedRegion.length && deletedValue) {
                alreadySelectedRegion.forEach((region) => {
                    onSelectItem(alreadySelectedRegion, {
                        action: "select-option",
                        name: "freeSearch",
                        option: region,
                    });
                });
                onSelectItem(alreadySelectedRegion, {
                    action: "remove-value",
                    name: "freeSearch",
                    removedValue: deletedValue,
                });
            }
            onSelectItem(alreadySelectedRegion, {
                action: "remove-value",
                name: "freeSearch",
                removedValue: selectRegion,
            });
        }
    }

    const switcherIcon = ({ isLeaf, expanded }: { isLeaf: boolean; expanded: boolean }) => {
        if (isLeaf) {
            return null; // No icon for leaf nodes
        }
        return expanded ? <FontAwesome name="caret-up" /> : <FontAwesome name="caret-down" />;
    };

    return options.displayType === "displayAsList" ? (
        <React.Fragment>
            {filteredItems.length && (
                <div className={`dynamic-free-search-wrap ${hideWidget}`}>
                    <div className="free-search-list">
                        {filteredItems.map((data, index) => (
                            <label key={index} className="free-search-item">
                                <input
                                    type="checkbox"
                                    onChange={() => getSelectedItems(data)}
                                    className={classNames("form-check-input")}
                                    checked={selectedItems.find((item) => item.value === data.value) ? true : false}
                                />
                                <span className="free-search-name">{data.value}</span>
                            </label>
                        ))}
                        {options.useToggleFilter && (
                            <a className="show-more-filters" onClick={handleToggleFilter}>
                                {showMore ? currentLocalized?.lessButtonText : currentLocalized?.moreButtonText}
                            </a>
                        )}
                    </div>
                </div>
            )}
        </React.Fragment>
    ) : (
        <div
            className={`multi-select-wrapper dynamic-free-search ${hideWidget} ${className} ${
                !selectedItems.length && highlightInput && !highlightColor?.includes("rgba") ? `box-shadow-${highlightColor}` : ""
            }`}
            style={!selectedItems.length && highlightInput && highlightColor?.includes("rgba") ? BoxModelUtil.highlightStyling(highlightColor) : {}}
        >
            {options.showParentAndChildRegionsInDropdown ? (
                !options.showMultiLevelDropdown ? (
                    <Select
                        value={selectedItems}
                        isMulti={!!isMultiSelectDropdown}
                        onChange={onSelectItem}
                        isClearable={true}
                        name="freeSearch"
                        components={{
                            Option: CustomCheckboxChildOption,
                            GroupHeading: CustomCheckboxParentOption,
                            ValueContainer,
                        }}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        options={allFreeSearchItems}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder={placeholder}
                        instanceId="react-select-dynamic-free-search"
                    />
                ) : (
                    <TreeSelect
                        transitionName="rc-tree-select-dropdown-slide-up"
                        choiceTransitionName="rc-tree-select-selection__choice-zoom"
                        placeholder={placeholder}
                        allowClear={false}
                        treeDefaultExpandAll={false}
                        treeCheckable={true}
                        multiple
                        value={selectedItems}
                        treeData={allFreeSearchItems}
                        treeNodeFilterProp="title"
                        onChange={handleTreeOnChange}
                        showCheckedStrategy={TreeSelect.SHOW_PARENT}
                        treeIcon={false}
                        showTreeIcon={false}
                        switcherIcon={switcherIcon}
                        maxTagCount={1}
                        maxTagPlaceholder={(omittedValues) => `+${omittedValues.length} selected`}
                    />
                )
            ) : (
                <Select
                    value={selectedItems}
                    onChange={onSelectItem}
                    isMulti={!!isMultiSelectDropdown}
                    isClearable={true}
                    name="freeSearch"
                    components={animatedComponents}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    options={allFreeSearchItems}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder={placeholder}
                    instanceId="react-select-dynamic-free-search"
                />
            )}
        </div>
    );
};

const processChildOptions = (options: FreeSearchSelectItem[], dynamicFilterRegionIds: number[], selectedFreeSearchItems: FreeSearchSelectItem[], optionsConfig: WidgetOptions) => {
    options.forEach((childOption) => {
        const freeSearch: FreeSearchData = childOption.freeSearchData;
        const length = freeSearch.regionId?.every((regionId) => dynamicFilterRegionIds?.includes(regionId)) ? 1 : 0;

        if (optionsConfig.useCustomFreeSearch && (freeSearch.contentType?.length || 0) > 0 && freeSearch.contentType?.length === length) {
            selectedFreeSearchItems.push(childOption);
        } else if (!optionsConfig.useCustomFreeSearch && length === 1) {
            selectedFreeSearchItems.push(childOption);
        }

        // Recursively process childOptions if they exist
        if (childOption.children?.length) {
            processChildOptions(childOption.children, dynamicFilterRegionIds, selectedFreeSearchItems, optionsConfig);
        }
    });
    const seen = new Set();
    const uniqueSelectedFreeSearchItems = selectedFreeSearchItems.filter((item) => {
        const duplicate = seen.has(item.value);
        seen.add(item.value);
        return !duplicate;
    });
    return uniqueSelectedFreeSearchItems;
};

const getSelectedItemsFromDynamicFilter = (params: { dynamicFilter: DynamicFilter; options: WidgetOptions; allFreeSearchItems: FreeSearchSelectItem[] }): FreeSearchSelectItem[] => {
    const { options, dynamicFilter, allFreeSearchItems } = params;
    if (dynamicFilter?.regionIds || dynamicFilter?.amenities || dynamicFilter?.resortids) {
        const { regionIds: dynamicFilterRegionIds, amenities: dynamicFilterAmenities, resortids: dynamicFilterResortIds, accokindids: dynamicFilterAccoKindIds } = dynamicFilter;
        const selectedFreeSearchItems: FreeSearchSelectItem[] = [];
        allFreeSearchItems.forEach((item: FreeSearchSelectItem) => {
            if (options.showParentAndChildRegionsInDropdown && !options.showMultiLevelDropdown && dynamicFilterRegionIds?.length && item.options?.length) {
                item.options.forEach((childOption) => {
                    const freeSearch: FreeSearchData = childOption.freeSearchData;
                    const length = freeSearch.regionId?.every((regionId) => dynamicFilterRegionIds?.includes(regionId)) ? 1 : 0;

                    if (options.useCustomFreeSearch && (freeSearch.contentType?.length || 0) > 0 && freeSearch.contentType?.length === length) {
                        selectedFreeSearchItems.push(childOption);
                    } else if (!options.useCustomFreeSearch && length === 1) {
                        selectedFreeSearchItems.push(childOption);
                    }
                });
            }
            if (options.showMultiLevelDropdown && item.children && dynamicFilterRegionIds?.length) {
                selectedFreeSearchItems.push(...processChildOptions(item.children, dynamicFilterRegionIds, selectedFreeSearchItems, options));
            }
            const freeSearch: FreeSearchData = item.freeSearchData;
            let length = 0;
            length = freeSearch.regionId?.length && freeSearch.regionId.every((regionId) => dynamicFilterRegionIds?.includes(regionId)) ? length + 1 : length;
            length = freeSearch.resortId?.length && freeSearch.resortId.every((resortId) => dynamicFilterResortIds?.includes(resortId)) ? length + 1 : length;
            length = freeSearch.accoKindId?.length && freeSearch.accoKindId.every((accoKindId) => dynamicFilterAccoKindIds?.includes(accoKindId)) ? length + 1 : length;
            length = freeSearch.amenities
                ?.toString()
                .split(",")
                .every((amenityId) => dynamicFilterAmenities?.includes(amenityId))
                ? length + 1
                : length;

            if (options.useCustomFreeSearch && (freeSearch.contentType?.length || 0) > 0 && freeSearch.contentType?.length === length) {
                selectedFreeSearchItems.push(item);
            } else if (!options.useCustomFreeSearch && length === 1) {
                selectedFreeSearchItems.push(item);
            }
        });
        return selectedFreeSearchItems;
    }
    return [];
};

const createFilterChangeActionPayload = (params: { selectedItems: FreeSearchSelectItem[]; removedItems?: FreeSearchSelectItem[]; isMultiSelectDropdown: boolean }) => {
    const { selectedItems, removedItems, isMultiSelectDropdown } = params;
    const regionIds: number[] = [];
    const resortIds: number[] = [];
    const accoKindIds: number[] = [];
    const amenityIds: string[] = [];
    const dynamicFreeSearchIds: number[] = selectedItems.map((selectedItem) => selectedItem.freeSearchData.id || -1);
    (removedItems?.length ? removedItems : selectedItems).forEach((freeSearchItem: FreeSearchSelectItem) => {
        regionIds.push(...(freeSearchItem.freeSearchData.regionId || []));
        resortIds.push(...(freeSearchItem.freeSearchData.resortId || []));
        accoKindIds.push(...(freeSearchItem.freeSearchData.accoKindId || []));
        amenityIds.push(...(freeSearchItem.freeSearchData.amenities?.length ? freeSearchItem.freeSearchData.amenities.toString().split(",") : []));
    });
    return {
        minprice: undefined,
        maxprice: undefined,
        amenities: amenityIds,
        resortids: resortIds,
        accokindids: accoKindIds,
        regionIds,
        dynamicFreeSearchIds,
        isMultiSelectDropdown,
        isFreeSearch: true,
    };
};

const dispatchFilterChange = (params: { selectedItems: FreeSearchSelectItem[]; dispatch: Dispatch<FilterChangeAction>; removedItems: FreeSearchSelectItem[]; isMultiSelectDropdown: boolean }) => {
    const { selectedItems, dispatch, removedItems, isMultiSelectDropdown } = params;
    const action: FilterChangeAction = {
        type: ActionType.FilterChange,
        filter: removedItems.length ? dynamicFilterType.removeDynamicFreeSearch : dynamicFilterType.addDynamicFreeSearch,
        payload: createFilterChangeActionPayload({ removedItems, selectedItems, isMultiSelectDropdown }),
    };
    dispatchNow<FilterChangeAction>(dispatch, action);
};
async function setUpdatedDataFromAvalability(props: {
    context: CMSProviderProperties;
    widgetOptions: WidgetOptions;
    availableRegions: ElasticFacetV2;
    uniqueAvailableRegions: number[];
    setAllUpdatedFreeSearchData: React.Dispatch<React.SetStateAction<FreeSearchData[]>>;
}) {
    const { context, widgetOptions, uniqueAvailableRegions, availableRegions, setAllUpdatedFreeSearchData } = props;
    const currentEnv = await getMxtsEnv(context, context.currentLocale.code);
    const updatedFreeSearchData = await getFreeSearchItems(ItemType.REGIONS, currentEnv, widgetOptions, availableRegions);
    const arrangedFreeSearchData: FreeSearchData[] = [];
    uniqueAvailableRegions.forEach((region) => {
        const foundFreeSearchItem = updatedFreeSearchData.find((freeSearchItem) => freeSearchItem.regionId?.includes(region));
        if (foundFreeSearchItem) {
            arrangedFreeSearchData.push(foundFreeSearchItem);
        }
    });
    setAllUpdatedFreeSearchData((prevValue) => {
        if (isEqual(prevValue, arrangedFreeSearchData)) {
            return prevValue;
        }
        return arrangedFreeSearchData;
    });
}
